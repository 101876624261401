import _isEmpty from 'lodash/isEmpty';
import _get from 'lodash/get';
import _has from 'lodash/has';
import _isNil from 'lodash/isNil';
import Numeral from 'numeral';
import differenceInYears from 'date-fns/differenceInYears';
import { getEmbedlyImageUrl } from 'utils/embedlyUtils';
import parser from 'html-react-parser';
import isbot from 'isbot';
import { getEmbeddedContainerUrl, isEmbedded } from './embedded';

export const safeDecodeURIComponent = str => {
  try {
    return decodeURIComponent(str);
  } catch (e) {
    return str;
  }
};

export const arrayToSentence = (arr, connector = 'and') => {
  const cleanArray = arr.filter(val => val);
  if (_isEmpty(cleanArray)) return null;
  if (cleanArray.length === 1) return cleanArray[0];
  const last = cleanArray.pop();
  return `${cleanArray.join(', ')} ${connector} ${last}`;
};

export const generateKey = () => {
  function s4() {
    return Math.floor((1 + Math.random()) * 0x10000)
      .toString(16)
      .substring(1);
  }
  return `${s4() + s4()}-${s4()}-${s4()}-${s4()}-${s4()}${s4()}${s4()}`;
};

export const emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const isJson = str => {
  try {
    const strObj = JSON.parse(str);
    if (typeof strObj === 'object') return true;
    throw new Error();
  } catch (e) {
    return false;
  }
};

export const getCrebilityScoreInLabel = score => {
  if (score === 88.8) return 'Credible'; // handle specific checking as per algolia logic
  switch (true) {
    case score >= 85:
      return 'Highly Credible';
    case score >= 70:
      return 'Credible';
    case score < 70 && score !== null:
      return 'Not Credible';
    default:
      return 'Credible';
  }
};

export const getCrebilityScoreInLetter = score => {
  if (score === 88.8) return 'B'; // handle specific checking as per algolia logic
  switch (true) {
    case score >= 97:
      return 'A+';
    case score >= 93:
      return 'A';
    case score >= 90:
      return 'A-';
    case score >= 87:
      return 'B+';
    case score >= 83:
      return 'B';
    case score >= 80:
      return 'B-';
    case score >= 77:
      return 'C+';
    case score >= 73:
      return 'C';
    case score >= 70:
      return 'C-';
    case score >= 60:
      return 'D';
    case score < 60 && score !== null:
      return 'F';
    default:
      return 'B';
  }
};

export const fallbackImage =
  'https://assets.crediblemind.com/images/cm-logo-blue.jpg';

export const getImageFile = (
  data,
  disableEmbedly = false,
  fallback = fallbackImage,
) => {
  const hasGQlImageData = !_isEmpty(_get(data, 'image.url'));
  const hasImage = !_isEmpty(_get(data, 'image.fields'));
  const hasImageUrl = !_isEmpty(_get(data, 'imageUrl'));
  const hasParsedImage = typeof _get(data, 'image') === 'string';
  let file = fallback; // default to logo always
  const { userAgent } = navigator;
  let isSafari = userAgent.indexOf('Safari') !== -1;
  const isChrome = userAgent.indexOf('Chrome') > -1;
  if (isChrome && isSafari) {
    isSafari = false;
  }
  if (hasImageUrl) {
    file = data.imageUrl;
  } else if (hasGQlImageData) {
    file = data.image.url;
  } else if (hasImage && !_isEmpty(_get(data, 'image.fields.file.url'))) {
    file = data.image.fields.file.url;
  } else if (hasParsedImage) {
    file = data.image;
  }
  if (file && isSafari && file.match(/\.(?:webp)/g)) {
    file = fallback;
  }
  if (file && !isSafari && file.match(/\.(?:heic|heif)/g)) {
    file = fallback;
  }

  if (addHttps(file) === fallback) return fallback;
  if (disableEmbedly) return addHttps(file);
  return getEmbedlyImageUrl(addHttps(file));
};

export const getImagesAndLinks = (data, fallback = fallbackImage) => {
  if (Array.isArray(_get(data, 'multipleImages'))) {
    const images = [];
    const links = [];

    data.multipleImages.forEach(asset => {
      const hasImage = !_isEmpty(_get(asset, 'fields'));

      let file = fallback;
      let link;
      if (hasImage && !_isEmpty(_get(asset, 'fields.file.url'))) {
        file = asset.fields.file.url;
        if (
          _get(asset, 'fields.description', '').startsWith('/') ||
          _get(asset, 'fields.description', '').startsWith('http')
        ) {
          link = asset.fields.description;
        }
      }

      images.push(file);
      links.push(link);
    });

    return [images, links];
  }

  const hasImage = !_isEmpty(_get(data, 'image.fields'));

  if (hasImage) {
    let file;
    let link;
    file = fallback;
    if (!_isEmpty(_get(data, 'image.fields.file.url'))) {
      file = data.image.fields.file.url;
    }
    if (
      _get(data, 'image.fields.description', '').startsWith('/') ||
      _get(data, 'image.fields.description', '').startsWith('http')
    ) {
      link = data.image.fields.description;
    }
    return [[file], [link]];
  }

  return [[], []];
};

export const addHttps = file => {
  let finalFile = file;
  const protocolReqEx = new RegExp('^(http|https|blob:https?)://', 'i');
  if (!protocolReqEx.test(file)) {
    finalFile = `https:${file}`;
  }
  return finalFile;
};

export const isBot = () => {
  const botPattern =
    '(googlebot/|Googlebot-Mobile|Googlebot-Image|Google favicon|Mediapartners-Google|bingbot|slurp|java|wget|curl|Commons-HttpClient|Python-urllib|libwww|httpunit|nutch|phpcrawl|msnbot|jyxobot|FAST-WebCrawler|FAST Enterprise Crawler|biglotron|teoma|convera|seekbot|gigablast|exabot|ngbot|ia_archiver|GingerCrawler|webmon |httrack|webcrawler|grub.org|UsineNouvelleCrawler|antibot|netresearchserver|speedy|fluffy|bibnum.bnf|findlink|msrbot|panscient|yacybot|AISearchBot|IOI|ips-agent|tagoobot|MJ12bot|dotbot|woriobot|yanga|buzzbot|mlbot|yandexbot|purebot|Linguee Bot|Voyager|CyberPatrol|voilabot|baiduspider|citeseerxbot|spbot|twengabot|postrank|turnitinbot|scribdbot|page2rss|sitebot|linkdex|Adidxbot|blekkobot|ezooms|dotbot|Mail.RU_Bot|discobot|heritrix|findthatfile|europarchive.org|NerdByNature.Bot|sistrix crawler|ahrefsbot|Aboundex|domaincrawler|wbsearchbot|summify|ccbot|edisterbot|seznambot|ec2linkfinder|gslfbot|aihitbot|intelium_bot|facebookexternalhit|yeti|RetrevoPageAnalyzer|lb-spider|sogou|lssbot|careerbot|wotbox|wocbot|ichiro|DuckDuckBot|lssrocketcrawler|drupact|webcompanycrawler|acoonbot|openindexspider|gnam gnam spider|web-archive-net.com.bot|backlinkcrawler|coccoc|integromedb|content crawler spider|toplistbot|seokicks-robot|it2media-domain-crawler|ip-web-crawler.com|siteexplorer.info|elisabot|proximic|changedetection|blexbot|arabot|WeSEE:Search|niki-bot|CrystalSemanticsBot|rogerbot|360Spider|psbot|InterfaxScanBot|Lipperhey SEO Service|CC Metadata Scaper|g00g1e.net|GrapeshotCrawler|urlappendbot|brainobot|fr-crawler|binlar|SimpleCrawler|Livelapbot|Twitterbot|cXensebot|smtbot|bnf.fr_bot|A6-Indexer|ADmantX|Facebot|Twitterbot|OrangeBot|memorybot|AdvBot|MegaIndex|SemanticScholarBot|ltx71|nerdybot|xovibot|BUbiNG|Qwantify|archive.org_bot|Applebot|TweetmemeBot|crawler4j|findxbot|SemrushBot|yoozBot|lipperhey|y!j-asr|Domain Re-Animator Bot|AddThis|Prerender|linkedinbot|slackbot|hubspot)';
  const re = new RegExp(botPattern, 'i');
  const { userAgent } = navigator;
  if (re.test(userAgent) || isbot(userAgent)) {
    return true;
  }
  return false;
};

export const isIE = () => {
  let ie = false;
  if (
    navigator.userAgent.indexOf('MSIE') !== -1 ||
    navigator.appVersion.indexOf('Trident/') > -1
  ) {
    /* Microsoft Internet Explorer detected in. */
    ie = true;
  }
  return ie;
};

export const isSafari = () =>
  (navigator.userAgent || '').indexOf('Safari') !== -1;

export const isIOS = () =>
  /iPad|iPhone|iPod|iPad Simulator|iPhone Simulator|iPod Simulator/.test(
    navigator.userAgent,
  );

export const capitalizeFirstLetter = word =>
  word.charAt(0).toUpperCase() + word.slice(1);

export const getFinalPrice = price => {
  let finalPrice = 0;
  if (price > 0) {
    if (price % 1 === 0) {
      finalPrice = Numeral(price).format('$0,0');
    } else {
      finalPrice = Numeral(price).format('$0,0.00');
    }
  }

  return finalPrice;
};

export const sanitize = value =>
  value
    .trim()
    .toLowerCase()
    .replace(/[^a-zA-Z0-9-]/g, '');

export function getSubdomain() {
  const hostname = window && window.location && window.location.hostname;
  if (!hostname) return null;

  const prodHostName = 'crediblemind.com';
  const isDemoClient = hostname === 'crediblemind.net';
  const demoClientBrand = 'demomadera'; // TODO: remove this and add it in config
  const netlifyDynamicDevUrlSuffix = 'romantic-bhabha-a4590e';
  let subDomain;
  const blockListSubDomains = [
    'romantic-bhabha-a4590e',
    'pro',
    'app',
    'dev',
    'd2n4q77awvr9gf',
    'd1ll992iome3tu',
    'd2bh1v9l5yfp23',
    'focused-jones-74fe38',
    'cm-staging',
    'dev-release',
    'suspicious-williams-107545',
    'dazzling-hoover-eeb5e6',
    'optimistic-khorana-0f465a',
    'd3i84g09rfns1a',
  ];
  const splittedHostName = hostname.replace('www.', '').split('.');
  if (hostname.includes(netlifyDynamicDevUrlSuffix)) return null;
  // if exactly 3, means it has subdomain specific for the current setup
  const splittedHostNameLen = splittedHostName.length;
  if (
    splittedHostNameLen === 3 ||
    (splittedHostNameLen > 3 && splittedHostName[2] === 'com')
  ) {
    // eslint-disable-next-line prefer-destructuring
    subDomain = splittedHostName[0];
  } else if (
    splittedHostNameLen === 2 &&
    splittedHostName[0] !== 'crediblemind' &&
    hostname !== prodHostName
  ) {
    // eslint-disable-next-line prefer-destructuring
    subDomain = splittedHostName[0];
  }

  return !blockListSubDomains.includes(subDomain)
    ? isDemoClient
      ? demoClientBrand
      : subDomain
    : null;
}

export const getDomain = () => {
  const hostname = window && window.location && window.location.hostname;

  const subDomain = getSubdomain();

  if (subDomain) {
    const splittedHostName = hostname.split('.');
    const splittedHostNameLen = splittedHostName.length;

    if (
      splittedHostNameLen === 3 ||
      (splittedHostNameLen > 3 && splittedHostName[2] === 'com')
    ) {
      return splittedHostName.splice(1).join('.');
    }
  }

  return hostname;
};

export const getParentShareUrl = () => {
  const parentUrl = getEmbeddedContainerUrl();
  if (!parentUrl) return null;
  const pageData = window.location.pathname.replace(/^\/+/, '');
  return `${parentUrl}?page=${pageData}`;
};

export const isInternal = profile => {
  const { email, role } = profile;
  if (/@crediblemind.com\s*$/.test(email) || role === 'admin') return true;
  return false;
};

export const filterSentry = data => {
  const excludedWorks = [
    'network error',
    'postmessage',
    'resizeobserver',
    'transactioninactiveerror',
    'timeout',
  ];
  return excludedWorks.some(word =>
    new RegExp(word, 'i').test(data.toLowerCase()),
  );
};

const LOCAL_STORAGE_TEST_NAME = 'islocalstorageenabledtest';
const SESSION_STORAGE_TEST_NAME = 'issessionstorageenabledtest';

export const isLocalStorageAllowed = () => {
  try {
    const randomString = generateKey();
    localStorage.setItem(LOCAL_STORAGE_TEST_NAME, randomString);

    if (localStorage.getItem(LOCAL_STORAGE_TEST_NAME) !== randomString) {
      localStorage.removeItem(LOCAL_STORAGE_TEST_NAME);
      return false;
    }
    localStorage.removeItem(LOCAL_STORAGE_TEST_NAME);
    return true;
  } catch (error) {
    localStorage.removeItem(LOCAL_STORAGE_TEST_NAME);
    return false;
  }
};

export const isSessionStorageAllowed = () => {
  try {
    const randomString = generateKey();
    sessionStorage.setItem(SESSION_STORAGE_TEST_NAME, randomString);

    if (sessionStorage.getItem(SESSION_STORAGE_TEST_NAME) !== randomString) {
      sessionStorage.removeItem(SESSION_STORAGE_TEST_NAME);
      return false;
    }
    sessionStorage.removeItem(SESSION_STORAGE_TEST_NAME);
    return true;
  } catch (error) {
    sessionStorage.removeItem(SESSION_STORAGE_TEST_NAME);
    return false;
  }
};

export const generateShareUrl = (baseUrl, type) =>
  sanitizeLink(
    `${baseUrl}${
      !isEmbedded() ? `?utm_campaign=share&utm_source=${type}` : ''
    }`,
  );

export const isURL = str => {
  const pattern = new RegExp(
    '^(https?:\\/\\/)?' + // protocol
    '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name and extension
    '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
    '(\\:\\d+)?' + // port
    '(\\/[-a-z\\d%_.~+]*)*' + // path
    '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
      '(\\#[-a-z\\d_]*)?$',
    'i',
  ); // fragment locator
  return pattern.test(str);
};

export const replacePlaceholders = (string, placeholdersMap) => {
  if (_isNil(string)) return string;
  if (_isEmpty(placeholdersMap) || _isEmpty(string)) return parser(string);

  return Object.keys(placeholdersMap).reduce(
    (res, key) =>
      parser(res.replace(new RegExp(key, 'g'), placeholdersMap[key])),
    string,
  );
};

const getNodeMarks = (node, i) =>
  _get(node, ['content', i, 'marks'], [])
    .map(mark => mark.type)
    .sort()
    .join();

const simplifyRichTextObject = data => {
  if (_has(data, 'content')) {
    const simplifiedContent = [];
    let i = 0;
    while (i < data.content.length) {
      let j = 1;
      if (data.content[i].nodeType === 'text') {
        const iMarks = getNodeMarks(data, i);
        let jMarks = getNodeMarks(data, i + j);
        while (
          _get(data, ['content', i + j, 'nodeType']) === 'text' &&
          iMarks === jMarks
        ) {
          j += 1;
          jMarks = getNodeMarks(data, i + j);
        }
        const simpleContent = {
          ...data.content[i],
          value: data.content
            .slice(i, i + j)
            .map(item => item.value)
            .join(''),
        };
        simplifiedContent.push(simpleContent);
      } else {
        simplifiedContent.push(simplifyRichTextObject(data.content[i]));
      }
      i += j;
    }
    return {
      ...data,
      content: simplifiedContent,
    };
  }
  return data;
};

const parseRichTextPlaceholders = (data, map) => {
  if (_has(data, 'content')) {
    return {
      ...data,
      content: data.content.map(item => parseRichTextPlaceholders(item, map)),
    };
  }
  return {
    ...data,
    value: replacePlaceholders(data.value, map),
  };
};

export const replaceRichTextPlaceholders = (content, placeholdersMap) => {
  if (_isEmpty(placeholdersMap)) return content;
  if (_isEmpty(content)) return content;

  const simplifiedContent = simplifyRichTextObject(content);
  const parsedContent = parseRichTextPlaceholders(
    simplifiedContent,
    placeholdersMap,
  );
  return parsedContent;
};

export const getUserFirstName = profile =>
  (_get(profile, 'firstName') || '').split(' ')[0] ||
  (_get(profile, 'displayName') || '').split(' ')[0];

export const getUserLastName = profile => {
  if (_has(profile, 'lastName')) return profile.lastName;
  if (_has(profile, 'displayName')) {
    const namePieces = _get(profile, 'displayName').split(' ');
    if (namePieces.length > 1) namePieces.slice(-1).toString();
  }
  return '';
};

export const getUserDisplayName = profile => {
  const firstName = getUserFirstName(profile);
  const lastName = getUserLastName(profile);

  if (firstName && lastName) return `${firstName} ${lastName}`;
  if (firstName) return firstName;
  if (lastName) return lastName;
  return null;
};

export const sanitizeLink = link => unescape(encodeURIComponent(link));

export const generateQuickGuid = () =>
  Math.random()
    .toString(36)
    .substring(2, 15) +
  Math.random()
    .toString(36)
    .substring(2, 15);

export const generateRandomChars = length => {
  let result = '';
  const characters =
    'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  const charactersLength = characters.length;
  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
};

export const getAgeFromBirthday = (birthdayMonthYear, start = Date.now()) =>
  differenceInYears(
    start,
    new Date(birthdayMonthYear.split('-')[0], birthdayMonthYear.split('-')[1]),
  );

export const getParsedUserAgeInfo = birthdayMonthYear => {
  if (!birthdayMonthYear) return {};

  const userParsedAge =
    birthdayMonthYear && getAgeFromBirthday(birthdayMonthYear);
  return { userBirthdayMonth: birthdayMonthYear, userParsedAge };
};

export const isValidCSSColor = colorString => {
  const tempElement = document.createElement('div');
  tempElement.style.color = colorString;
  return tempElement.style.color !== '';
};

export const getDurationLabel = (copyObject, count) => {
  const key =
    count > 1 ? 'durationLabelShortPlural' : 'durationLabelShortSingular';
  return _get(copyObject, ['pageCopy', key]);
};

export const getLowerCaseEmailDomain = email => {
  const emailDomain = email.split('@')[1];
  const lowerCaseEmailDomain =
    typeof emailDomain === 'string' ? emailDomain.toLowerCase() : emailDomain;

  return lowerCaseEmailDomain;
};

export const getTrackingPageName = () => {
  const fallback = window.location.pathname.split('/')[1];
  const pageName = /learning-lab/.test(window.location.pathname)
    ? window.location.hash.slice(1) ?? fallback
    : fallback;
  return pageName ? `${pageName}Page` : 'homePage';
};

export const cleanQueryParams = queryString => {
  const params = new URLSearchParams(queryString);

  // eslint-disable-next-line no-restricted-syntax
  for (const [key, value] of params.entries()) {
    // Remove extra quotes from the value
    const cleanedValue = value.replace(/^['"]+|['"]+$/g, '');
    params.set(key, cleanedValue);
  }

  return params.toString();
};

export const getReportingSamlData = profile => {
  const profileSamlData = {
    mid:
      _get(profile, 'samlData.MID') ||
      _get(profile, 'custom.mid', []).join(','),
    customConsent: _get(profile, 'custom.customConsent'),
  };

  return Object.keys(profileSamlData).reduce((res, key) => {
    if (res[key] === undefined) {
      delete res[key];
    }
    return res;
  }, profileSamlData);
};

export const getProviderId = (auth, provider) => {
  if (!provider || provider.password) {
    return 'password';
  }

  if (provider.provider) {
    return provider.provider;
  }

  const { providerData } = auth;
  return providerData[providerData.length - 1].providerId;
};
