/**
 * Client redirect configuration
 * Maps client subdomains to their respective CredibleMind URLs
 *
 * Format:
 * [subdomain]: {
 *   url: string (required) - The target URL for redirection
 *   group?: string (optional) - Group identifier for related clients
 *   isActive?: boolean (optional) - Whether this redirect is currently active
 * }
 */

const BASE_DOMAIN = 'crediblemind.com';

// Group related clients for better organization
const CLIENT_GROUPS = {
  CHOOSE_WELL: 'choosewell',
};

/**
 * Configuration for closed/retired client sites
 * Maps closed client subdomains to their redirect destinations
 */
export const closedClients = {
  gopro: `https://${BASE_DOMAIN}`,
  garfield: `https://${BASE_DOMAIN}`,
  garcopeer: `https://${BASE_DOMAIN}`,
  a4c: `https://${BASE_DOMAIN}`,
  dunn: `https://${BASE_DOMAIN}`,
  glenmede: `https://${BASE_DOMAIN}`,
  drh: `https://${BASE_DOMAIN}`,
  kpsanjose: `https://${BASE_DOMAIN}`,
  missionfed: `https://${BASE_DOMAIN}`,
  kaisersanjose: `https://${BASE_DOMAIN}`,
  kaisersanfrancisco: `https://${BASE_DOMAIN}`,
};

export const clientRedirects = {
  owensboro: {
    url: `https://healthymind.${BASE_DOMAIN}`,
  },
  greenwood: {
    url: `https://lakelands.${BASE_DOMAIN}`,
  },
  dhec: {
    url: `https://scdhec.${BASE_DOMAIN}`,
  },
  providence: {
    url: `https://choosewell.${BASE_DOMAIN}`,
    group: CLIENT_GROUPS.CHOOSE_WELL,
  },
  pbh: {
    url: `https://choosewell.${BASE_DOMAIN}`,
    group: CLIENT_GROUPS.CHOOSE_WELL,
  },
  swedish: {
    url: `https://choosewell.${BASE_DOMAIN}`,
    group: CLIENT_GROUPS.CHOOSE_WELL,
  },
  testmonterey: {
    url: `https://wellscreenmonterey.${BASE_DOMAIN}`,
  },

  franklin: {
    url: `https://franklincountyohio.${BASE_DOMAIN}`,
  },
  dentoncounty: {
    url: `https://unitedwaydenton.${BASE_DOMAIN}`,
  },
  lifeadvantages: {
    url: `https://firstsun.${BASE_DOMAIN}`,
  },
  hendricks: {
    url: `https://hendrickscounty.${BASE_DOMAIN}`,
  },
  'lincoln-lancaster': {
    url: `https://myhealthymind.${BASE_DOMAIN}`,
  },
  wincoil: {
    url: `https://taketen815.${BASE_DOMAIN}`,
  },
  cavaliercounty: {
    url: `https://borderland.${BASE_DOMAIN}`,
  },
};

/**
 * Get the redirect URL for a given client subdomain
 * @param {string} subdomain - The client subdomain
 * @param {string} [pathname=''] - Optional pathname to append to the URL
 * @returns {string|null} The redirect URL or null if not found
 */
export const getClientRedirectUrl = (subdomain, pathname = '') => {
  // First check if it's a closed client
  if (closedClients[subdomain]) {
    return `${closedClients[subdomain]}${pathname}`;
  }

  // Then check active clients
  const client = clientRedirects[subdomain];
  if (!client) return null;
  return `${client.url}${pathname}`;
};

/**
 * Check if a client is closed/retired
 * @param {string} subdomain - The client subdomain to check
 * @returns {boolean} True if the client is in the closed clients list
 */
export const isClosedClient = subdomain => Boolean(closedClients[subdomain]);

/**
 * Get all clients belonging to a specific group
 * @param {string} groupName - The group name to filter by
 * @returns {Array<string>} Array of client subdomains in the group
 */
export const getClientsByGroup = groupName =>
  Object.entries(clientRedirects)
    .filter(([, config]) => config.group === groupName)
    .map(([subdomain]) => subdomain);

export { CLIENT_GROUPS };
