import { sanitize } from 'utils/stringUtils';

export const redirectToSubdomain = (subdomain, path, isUat) => {
  if (!subdomain || typeof subdomain !== 'string') {
    throw new Error('Subdomain must be a non-empty string');
  }

  const sanitizedSubdomain = sanitize(subdomain);

  try {
    const newUrl = new URL(
      path,
      `https://${sanitizedSubdomain}${isUat ? '-uat' : ''}.crediblemind.com`,
    );

    setTimeout(() => {
      window.location.href = newUrl.toString();
    }, 5000);
  } catch (e) {
    console.error('Failed to perform subdomain redirect:', e);
    throw e;
  }
};
