import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { setLeavingModal } from 'containers/Main/actions';
import _get from 'lodash/get';
import _some from 'lodash/some';
import { makeSelectAuth, makeSelectProfile } from 'containers/Auth/selectors';
import { makeSelectClientDetails } from 'containers/Main/selectors';
import useIsAuthPath from 'components/Hooks/useIsAuthPath';
import { getSubdomain } from 'utils/stringUtils';
import { isLocalhost } from 'utils/networkUtils';
import { redirectToSubdomain } from './utils';

function useHCSCEffect() {
  const history = useHistory();
  const dispatch = useDispatch();
  const auth = useSelector(makeSelectAuth());
  const clientDetails = useSelector(makeSelectClientDetails());
  const profile = useSelector(makeSelectProfile());
  const isAuthenticationPage = useIsAuthPath();

  const isHCSC =
    _get(clientDetails, 'clientGroup.formalShortName') === 'HCSC' ||
    _get(clientDetails, 'clientGroup.formalShortName') === 'HCSC UAT';

  useEffect(() => {
    if (
      isHCSC &&
      _get(clientDetails, 'customAuthentication.enabled') &&
      !isAuthenticationPage &&
      auth.isLoaded &&
      !auth.isEmpty &&
      profile.isLoaded &&
      !profile.isEmpty &&
      !isLocalhost
    ) {
      if (!profile.email) {
        history.push('/signup/email');
      } else if (
        _get(profile, 'custom.eligibility.client') &&
        !getSubdomain().includes(_get(profile, 'custom.eligibility.client'))
      ) {
        if (
          _some(_get(auth, 'providerData', []), provider =>
            provider.providerId.includes('saml.'),
          )
        ) {
          history.push('/auth/sso');
        } else {
          dispatch(
            setLeavingModal({
              type: 'redirect',
              visible: true,
            }),
          );

          setTimeout(() => {
            redirectToSubdomain(
              _get(profile, 'custom.eligibility.client'),
              '/auth/login',
              _get(clientDetails, 'clientGroup.formalShortName') === 'HCSC UAT',
            );
          }, 3000);
        }
      }
    }
  }, [auth, clientDetails, history, isAuthenticationPage, isHCSC, profile]);
}

export default useHCSCEffect;
